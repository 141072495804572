

@font-face {
  font-family: 'Eurostile Regular';
  src: url('../../tcgportal/src/app/assets/fonts/eurostile.woff') format('woff'),
       url('../../tcgportal/src/app/assets/fonts/EuroStyle-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.sidenavHoverShow {
  font-family: 'Eurostile Regular' !important;
  font-size: 1.1rem !important;
}

td span {
  font-size: 0.98rem !important;
}

th {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

span {
  font-size: 1rem !important;
}

.MuiButtonBase-root {
  font-size: 1.1rem !important;
}

.muibtn_searchBy {
  font-size: 10px !important;
}

h5 {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

.PhoneInput input {
    height: 30px !important;
    border: none !important;
  }
  

  #reg PhoneInput{
    margin-top: 20px;
  }

  /* .css-m6cb7p-MuiTableCell-root {
    border-bottom: none !important;
  }

  .css-9mgopn-MuiDivider-root {
    border-bottom: none !important;
  } */
  
  #reg .PhoneInput{
    margin-top: 0px;
    margin-bottom: 5px;
  }


  .reportClass {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    height: 100%; /* Adjusted to fit the height of its container */
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#embed-container {
  height: 600px;
  min-height: 600px;;
}


/* Additional styling for responsiveness */
@media (max-width: 768px) {
    .reportClass {
        height: 400px; /* Adjust height for smaller screens */
    }
}